import React, { useEffect, useState } from 'react';
import { fetchAllItemsMaster, fetchAlllivesales } from '../api/api';

export default function DisplayPage() {
  const [itemsMaster, setItemsMaster] = useState([]); // Store master items
  const [liveItems, setLiveItems] = useState([]);
  const [joinedItems, setJoinedItems] = useState([]); // Store joined data
  const [labelColors, setLabelColors] = useState({}); // Store dynamic label colors
  const username = localStorage.getItem('username'); // Retrieve username from local storage

  // const colors = ['green', 'orange', 'blue', 'red', 'purple', 'yellow', 'pink', 'brown'];
  const colors = [
    'blue', 'red', 'purple', 'brown', 'magenta', 'teal', 'green',
    'maroon', 'navy', 'olive', 'crimson', 'black', 'azure'
  ];

  useEffect(() => {
    const fetchData = async () => {
      await fetchItemsMaster();
      await fetchLiveItems();
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Fetch master items once at the beginning
    const fetchInitialData = async () => {
      await fetchItemsMaster();
    };
    fetchInitialData();

    // Set up interval to fetch live items every 2 seconds
    const intervalId = setInterval(() => {
      fetchLiveItems();
    }, 2000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const fetchLiveItems = async () => {
    try {
      const itemsbeforefilter = await fetchAlllivesales();
      // Filter items where the user matches the username
      const items = itemsbeforefilter.filter(item => item.user === username && item.saleqty > 0);

      // Aggregate items by the 'item' field
      const aggregatedItems = items.reduce((acc, curr) => {
        const existingItem = acc.find((item) => item.item === curr.item);
        if (existingItem) {
          // Combine or aggregate fields as needed
          existingItem.saleqty += curr.saleqty; // Example of summing a 'quantity' field
        } else {
          acc.push({ ...curr }); // Add a new item if it doesn't exist
        }
        return acc;
      }, []);

      console.log('Aggregated Live Items:', aggregatedItems);
      setLiveItems(aggregatedItems);
    } catch (error) {
      console.error('Error fetching live items:', error);
    }
  };

  const fetchItemsMaster = async () => {
    try {
      const response = await fetchAllItemsMaster();
      console.log('Master Items:', response);
      setItemsMaster(response || []);
    } catch (error) {
      console.error('Error fetching master items:', error);
    }
  };

  useEffect(() => {
    // Perform the join when either liveItems or itemsMaster changes
    const joinData = () => {
      const joined = liveItems.map(liveItem => {
        const masterItem = itemsMaster.find(master => master.id == liveItem.item);
        return {
          ...liveItem,
          masterItemName: masterItem ? masterItem.item : 'Unnamed Item',
          label: masterItem?.label,
        };
      });
    
      // Sort joined items by the label field
      const sortedJoined = joined.sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
    
      console.log('Sorted Joined Items:', sortedJoined);
      setJoinedItems(sortedJoined);
    
      // Extract unique labels and assign colors
      const uniqueLabels = [...new Set(sortedJoined.map(item => item.label))];
      assignColorsToLabels(uniqueLabels);
    };    

    if (liveItems.length && itemsMaster.length) {
      joinData();
    }
  }, [liveItems, itemsMaster]);

  const assignColorsToLabels = (labels) => {
    const newLabelColors = {};
    let colorIndex = 0;

    labels.forEach((label) => {
      if (!newLabelColors[label]) {
        newLabelColors[label] = colors[colorIndex];
        colorIndex = (colorIndex + 1) % colors.length; // Cycle through colors
      }
    });

    setLabelColors(newLabelColors);
  };

  // Calculate totals
  const totalQty = joinedItems.reduce((sum, item) => sum + parseFloat(item.saleqty || 0), 0);
  const totalPrice = joinedItems.reduce((sum, item) => sum + parseFloat(item.saleprice || 0), 0);
  const totalAmount = joinedItems.reduce((sum, item) => sum + parseFloat(item.saleqty * item.saleprice || 0), 0);

  return (
    <div className="container mt-4">
      <h6 className="text-success">
        <u>Display Items</u>
      </h6>
      <table className="table table-striped" border="1">
        <thead>
          <tr>
            <th></th>
            <th className="text-success">Item</th>
            <th className="text-success">Label</th>
            <th className="text-success">QTY</th>
            <th className="text-success">Price</th>
            <th className="text-success">Total</th>
          </tr>
        </thead>
        <tbody>
          {joinedItems.map((item, index) => (
            <tr key={item.id}>
              <td style={{ backgroundColor: labelColors[item.label] || 'white', color: 'white' }}>{index + 1}</td>
              <td style={{ backgroundColor: labelColors[item.label] || 'white', color: 'white' }}>
                {item.masterItemName.includes('#1')
                  ? item.masterItemName.replace('#1', '(C)')
                  : item.masterItemName.includes('#2')
                  ? item.masterItemName.replace('#2', '(P)')
                  : item.masterItemName.includes('#3')
                  ? item.masterItemName.replace('#3', '(WL)')
                  : item.masterItemName
                }
              </td>
              <td style={{ backgroundColor: labelColors[item.label] || 'white', color: 'white' }}>{item.label}</td>
              <td style={{ backgroundColor: labelColors[item.label] || 'white', color: 'white' }}>{item.saleqty}</td>
              <td style={{ backgroundColor: labelColors[item.label] || 'white', color: 'white' }}>{item.saleprice}</td>
              <td style={{ backgroundColor: labelColors[item.label] || 'white', color: 'white' }}>{item.saleqty * item.saleprice}</td>
            </tr>
          ))}
        </tbody>
        <thead>
          <tr>
            <th></th>
            <th className="text-success">Total</th>
            <th className="text-success"></th>
            <th className="text-success">{totalQty.toLocaleString()}</th>
            <th className="text-success">{totalPrice.toLocaleString()}</th>
            <th className="text-success">{totalAmount.toLocaleString()}</th>
          </tr>
        </thead>
      </table>
    </div>
  );
}
