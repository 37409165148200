import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import { fetchAllItemsMaster, creatingOrder } from '../../api/api';

export default function Orders() {
  const [items, setItems] = useState([]);
  const [formData, setFormData] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedLabel, setSelectedLabel] = useState('');
  const [designation, setDesignation] = useState('');

  useEffect(() => {
    fetchItems();
    const userDesignation = localStorage.getItem('designation');
    setDesignation(userDesignation);
  }, []);

  const fetchItems = async () => {
    try {
      const items = await fetchAllItemsMaster();
      console.log(items);
      const filteredItems = items.filter(item => item.pushtows === 1);

      setItems(filteredItems);
      // Initialize formData with default values for order quantity
      const initialFormData = {};
      items.forEach(item => {
        initialFormData[`sellqty_${item.id}`] = 0;
      });
      setFormData(initialFormData);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Prepare itemsData object with updated sell prices, order quantities, and labels
      const itemsData = [];
      console.log('FormData:', formData);
      console.log('Items:', items);

      for (const key in formData) {
        const [field, itemId] = key.split('_');
        if (field === 'sellqty' && formData[key] > 0) {
          // Extract the order quantity and price
          const sellQty = formData[key];

          // Ensure itemId is a string or number, depending on the type in items array
          const item = items.find(item => item.id.toString() === itemId.toString());

          if (item) {
            const label = item.label; // Find the corresponding label
            const saleprice = item.saleprice; // Find the corresponding label
            console.log('Item found:', item);

            // Add item data to the itemsData array
            itemsData.push({ id: itemId, sellqty: sellQty, label: label, saleprice: saleprice });
          } else {
            console.warn(`Item with id ${itemId} not found. Available IDs: ${items.map(item => item.id).join(', ')}`);
          }
        }
      }

      // Check if itemsData array is empty
      if (itemsData.length === 0) {
        // Show toast message if itemsData is empty
        toast.error('Please add items to the order with quantities');
        return; // Stop further execution
      }

      // Additional data from the form
      const saleType = 'wholesale'; // Adjust this as needed
      const saleid = uuidv4();
      const customer = localStorage.getItem('name');

      // Create the order object
      const orderData = {
        saleType,
        customer,
        saleid,
        items: itemsData
      };

      // Call the API function to handle the order and show toast message
      const response = await creatingOrder(orderData);

      console.log(response.message);

      if (response.message === 'sold') {
        toast.success('Order created successfully');
        // Wait for 2 seconds before reloading the page
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        setFormData({});
      } else {
        toast.error('Error creating order. Please try again.');
      }

    } catch (error) {
      console.error('Error creating order:', error);
      // Show error message
      toast.error('Error creating order. Please try again.');
    }
  };

  // for search 
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleLabelChange = (e) => {
    setSelectedLabel(e.target.value);
  };

  const getUniqueLabels = () => {
    const labels = items.map(item => item.label);
    return [...new Set(labels)];
  };

  const filteredItems = items.filter(item =>
    item.item.toLowerCase().includes(searchQuery.toLowerCase()) &&
    (!selectedLabel || item.label === selectedLabel)
  );

  return (
    <>
      <div className='mt-5'>
        {designation === 'customer' ?
            <Link to={`/your-orders/${localStorage.getItem('name')}`} className='float-end mx-2'>
              <button className='btn btn-success'>Your Orders</button>
            </Link> : 
            <div></div>
        }
        
        <input
            className='form-control w-25 float-end'
            type="text"
            placeholder="Search items..."
            value={searchQuery}
            onChange={handleSearchChange}
        />

        <select
          className='form-control w-25 float-end mx-2'
          value={selectedLabel}
          onChange={handleLabelChange}
        >
          <option value=''>All Labels</option>
          {getUniqueLabels().map(label => (
            <option key={label} value={label}>{label}</option>
          ))}
        </select>

        {designation === 'customer' ?
            <button className='float-end mx-2 btn btn-warning' onClick={handleSubmit}>Place Order</button>
             : 
            <div></div>
        }

        <h5><u className='titilescolor'>Items</u></h5>
        <table className="table-responsive table table-striped">
          <thead>
          <tr className='theads'>
            <td><strong>#</strong></td>
            <th>Item</th>
            <th>Labels</th>
            <th>QTY</th>
          </tr>
          </thead>
          <tbody>
          {filteredItems.map((item, index) => (
            <tr key={item.id}>
                <td>{index + 1}</td>
                {/* <td>{item.item}</td> */}
                <td>
                  {
                    item.item.includes("#1") ? item.item.replace("#1", "creame") :
                    item.item.includes("#2") ? item.item.replace("#2", "pink") :
                    item.item.includes("#3") ? item.item.replace("#3", "orange") :
                    item.item
                  }
                </td>
                <td>{item.label}</td>
                <td><input type="number" min='0' className='form-control' name={`sellqty_${item.id}`} value={formData[`sellqty_${item.id}`]} onChange={handleChange} onFocus={(e) => e.target.select()} style={{ minWidth: '100px' }}  /></td>
            </tr>
            ))}
          </tbody>
        </table>
        <ToastContainer />
      </div>
    </>
  );
}
