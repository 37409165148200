import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchAllSalesStatusForWholeSale, fetchAllSalesW } from '../../api/api';

export default function Wholesalecustomer() {
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [items, setItems] = useState([]);
  const inputRefs = useRef({});
  
  const [searchParams] = useSearchParams();
  const selectedCustomer = searchParams.get('customer');  // This will get the 'customer' query param from the URL

  useEffect(() => {
    const interval = setInterval(() => {
      fetchItems(); // Your existing fetch function
    }, 2000); // Fetch every 2 seconds
  
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);
  

  const fetchItems = async () => {
    try {
      const allItems = await fetchAllSalesW();
      const uniqueItems = new Map();
      console.log('wholesale', allItems);
  
      allItems.forEach(item => {
        // if (item.customer === selectedCustomer) {
        if (item.customer === selectedCustomer && item.itemid && item.itemid.id) { 
          if (!uniqueItems.has(item.itemid.id)) {
            uniqueItems.set(item.itemid.id, { ...item, saleqty: {}, total: 0 });
          }
          const currentItem = uniqueItems.get(item.itemid.id);
          currentItem.saleqty[item.customer] = item.saleqty;
          currentItem.total += item.saleqty * item.saleprice;
        }
      });
  
      setItems(Array.from(uniqueItems.values()));
      console.log('Filtered for customer1', Array.from(uniqueItems.values()));
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };         

  const handlePriceChange = (event, itemIndex) => {
    const newItems = [...items]; // Create a copy of the items array
    newItems[itemIndex].saleprice = parseFloat(event.target.value);
    setItems(newItems);
    calculateTotalPerCustomer(); // Recalculate totals after price change
  };

  const handleQuantityChange = (event, itemIndex, customer) => {
    const newItems = [...items]; // Create a copy of the items array
    const newSaleQty = parseInt(event.target.value, 10);
    newItems[itemIndex].saleqty[customer] = newSaleQty;
    newItems[itemIndex].total = Object.values(newItems[itemIndex].saleqty).reduce(
      (acc, qty) => acc + qty * newItems[itemIndex].saleprice,
      0
    );
    setItems(newItems);
  };

  const calculateTotalPerCustomer = () => {
    setItems((prevItems) =>
      prevItems.map((item) => {
        const updatedItem = { ...item };
        updatedItem.total = Object.keys(item.saleqty).reduce((acc, customer) => {
          return acc + item.saleqty[customer] * item.saleprice;
        }, 0);
        return updatedItem;
      })
    );
  };

  const calculateTotalOrders = (itemId) => {
    return items
      .filter(item => item.id === itemId)
      .reduce((acc, item) => acc + Object.values(item.saleqty).reduce((a, b) => a + b, 0), 0);
  };  

  const getCustomers = () => {
    const customersSet = new Set();
    items.forEach(item => {
      Object.keys(item.saleqty).forEach(customer => customersSet.add(customer));
    });
    return Array.from(customersSet);
  }; 

  // showing the clients items that have qty greater than 0
  const filteredItems = items.filter(item => {
    // Calculate the total quantity for the item
    const totalQuantity = Object.values(item.saleqty).reduce((acc, qty) => acc + qty, 0);
    return totalQuantity > 0;  // Only include items with a quantity greater than 0
  });

  // Sort filtered items by label so that items with the same label appear together
  const sortedItems = filteredItems.sort((a, b) => {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  }); 

  const getColorForLabel = (label) => {
    // Create a hash for the label
    const hash = label
      .split('')
      .reduce((acc, char) => acc + char.charCodeAt(0), 0);
  
    // Generate a color from the hash
    const colors = [
      'blue', 'red', 'purple', 'brown', 'magenta', 'teal', 'green',
      'maroon', 'navy', 'olive', 'crimson', 'black', 'azure'
    ];
    
    return colors[hash % colors.length];
  };
  

  return (
    <>
      <div className='container'>
        <div>
          {/* Render different components based on the active screen */}
          {activeScreen === 'mastersheet' && (
            <div>
              <div className='mt-1'>
                <h6 className=''><u>Welcome: {selectedCustomer}</u></h6>
                <form className=''>
                  <div className=''>
                    <table className='table table-striped' style={{ minWidth: '100%' }}>
                      <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
                        <tr>
                          <th className='sticky-column bg-success text-light'>Item</th>
                          <th className='sticky-column bg-success text-light'>Label</th>
                          <th className='sticky-column bg-success text-light'>Price</th>
                          <th className='sticky-column bg-success text-light'>QTY</th>
                        </tr>
                      </thead>

                      <tbody>
                        {sortedItems.map((item, itemIndex) => {
                          const totalOrders = calculateTotalOrders(item.id);
                          const balance = item.itemid.masterqty - totalOrders;
                          return (
                            <tr key={item.id}>
                              <td style={{padding: '0rem', backgroundColor: getColorForLabel(item.label), color: '#fff'}} >
                              {
                                item.itemid.item.includes('#1')
                                  ? item.itemid.item.replace('#1', '(C)')
                                  : item.itemid.item.includes('#2')
                                  ? item.itemid.item.replace('#2', '(P)')
                                  : item.itemid.item.includes('#3')
                                  ? item.itemid.item.replace('#3', '(WL)')
                                  : item.itemid.item
                              }
                              </td>
                              <td style={{padding: '0rem', backgroundColor: getColorForLabel(item.label), color: '#fff'}}>{item.label}</td>
                              <td style={{padding: '0rem' }}>
                                <input
                                  style={{backgroundColor: getColorForLabel(item.label), color: '#fff'}}
                                  min='0'
                                  className='form-control'
                                  value={item.saleprice}
                                  onChange={(e) => handlePriceChange(e, itemIndex)}
                                />
                              </td>
                              {getCustomers().map((customer, customerIndex) => (
                                <td className='' key={customerIndex} style={{padding: '0rem'}}>
                                  <input
                                    style={{backgroundColor: getColorForLabel(item.label), color: '#fff'}}
                                    min='0'
                                    className='form-control'
                                    ref={(el) => inputRefs.current[`${itemIndex}-${customerIndex}`] = el}
                                    // onKeyDown={(e) => handleKeyDown(e, itemIndex, customerIndex)}
                                    onChange={(e) => handleQuantityChange(e, itemIndex, customer)}
                                    value={item.saleqty[customer] || 0}
                                  />
                                </td>
                              ))}
                            </tr>
                          );
                        })}
                      </tbody>

                      <thead>
                      <tr>
                        <th className="sticky-column">Total Number of Bales</th>
                        <th className="sticky-column"></th>
                        <th className="sticky-column"></th>
                        {items.length > 0 && (
                          <React.Fragment>
                            {getCustomers().map((customer, index) => (
                              <React.Fragment key={index}>
                                <th
                                  className="sticky-column"
                                  style={{
                                    position: 'sticky',
                                    top: 0,
                                    backgroundColor: 'white',
                                    zIndex: -2,
                                    color: 'blue',
                                  }}
                                >
                                  {' '}
                                  {items.reduce((acc, currentItem) => {
                                    return acc + (currentItem.saleqty[customer] || 0);
                                  }, 0)}
                                </th>
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        )}
                      </tr>

                        <tr>
                          <th className='sticky-column'>Total Payable</th>
                          <th className='sticky-column'></th>
                          <th className='sticky-column'></th>
                          {items.length > 0 && (
                            <React.Fragment>
                              {getCustomers().map((customer, index) => (
                                <th className='sticky-column' key={index} style={{ color: 'green' }}>
                                  {items.reduce((acc, currentItem) => {
                                    return acc + (currentItem.saleqty[customer] || 0) * currentItem.saleprice;
                                  }, 0).toLocaleString()}
                                </th>
                              ))}
                            </React.Fragment>
                          )}
                        </tr>
                        <tr>
                          <th className='sticky-column'></th>
                          <th className='sticky-column'></th>
                          <th className='sticky-column'></th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </form>

                <ToastContainer />
              </div>
            </div>
          )}

          {/* other code starts here  */}
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}