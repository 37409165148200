import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchingComplains } from '../../api/api';

export default function Goodcomplain() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [sales, setSales] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');

  const today = new Date().toISOString().split('T')[0];

  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);

  const [subTotals, setSubTotals] = useState({ qty: 0, amount: 0, total: 0 });

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  useEffect(() => {
    fetchSales();
  }, []);

  useEffect(() => {
    calculateSubtotals();
  }, [sales, searchQuery, startDate, endDate]);

  const fetchSales = async () => {
    try {
      const salesData = await fetchingComplains();
      console.log(salesData)
      setSales(salesData);
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const filteredSales = sales.filter((sale) => {
    const saleDate = new Date(sale.createdAt);
    const start = new Date(startDate);
    const end = new Date(endDate);
    return (
      sale.customerName.toLowerCase().includes(searchQuery.toLowerCase()) &&
      (!startDate || saleDate >= start) &&
      (!endDate || saleDate <= end)
    );
  });

  const calculateSubtotals = () => {
    let qtySubtotal = 0;
    let amountSubtotal = 0;
    let totalSubtotal = 0;

    filteredSales.forEach((sale) => {
      qtySubtotal += sale.qty;
      amountSubtotal += sale.amount;
      totalSubtotal += sale.amount * sale.qty;
    });

    setSubTotals({
      qty: qtySubtotal,
      amount: amountSubtotal,
      total: totalSubtotal,
    });
  };

  return (
    <>
      <Navbar />
      <div className="homepage">
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />

        <div className={`container-fluid my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {activeScreen === 'mastersheet' && (
            <div className="mt-4">
              <h6>
                <u className="titilescolor">Good Complain</u>
              </h6>
              <div className="d-flex justify-content-end mb-3">
                <input
                  type="text"
                  className="form-control w-25 me-2"
                  placeholder="Search by customer"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <input
                  type="date"
                  className="form-control w-25 me-2"
                  value={startDate}
                  onChange={handleStartDateChange}
                />
                <input
                  type="date"
                  className="form-control w-25"
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </div>
              <table className="table table-striped">
                <thead>
                  <tr className="theads">
                    <td>
                      <strong>#</strong>
                    </td>
                    <th>Customer</th>
                    <th>Item</th>
                    <th>Label</th>
                    <th>QTY</th>
                    <th>Amount</th>
                    <th>Total</th>
                    <th>Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className='resizetable'>
                  {filteredSales.map((sale, index) => (
                    <tr key={sale.saleid}>
                      <td>{index + 1}</td>
                      <td>{sale.customerName}</td>
                      <td>{sale.itemname}</td>
                      <td>{sale.itemlabel}</td>
                      <td>{sale.qty}</td>
                      <td>{Number(sale.amount).toLocaleString()}</td>
                      <td>{Number(sale.amount * sale.qty).toLocaleString()}</td>
                      <td>{sale.createdAt}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="1"></td>
                    <td className='text-success' colSpan="3"><strong>Subtotals</strong></td>
                    <td className='text-success'><strong>{subTotals.qty}</strong></td>
                    <td className='text-success'><strong>{Number(subTotals.amount).toLocaleString()}</strong></td>
                    <td className='text-success'><strong>{Number(subTotals.total).toLocaleString()}</strong></td>
                    <td></td>
                  </tr>
                </tfoot>
              </table>
              <ToastContainer />
            </div>
          )}

          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
