import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie';
import logo from '../images/svtrading.jpg';

export default function Navbar() {
    const navigate = useNavigate();
    const [designation, setDesignation] = useState(''); // State to store the user's designation
    const cookies = new Cookies();
    const [isDarkMode, setIsDarkMode] = useState(false);

     // logout 
    // const handleLogout = () => {
    //   const localStorageKeys = ['userid', 'token', 'designation', 'username', 'email', 'department', 'phone', 'credit_limit', 'name'];
    //   localStorageKeys.forEach(key => localStorage.removeItem(key));
    //   // Clear cookies
    //   ['jwt', 'designation'].forEach(cookie => cookies.remove(cookie, { path: '/' }));
    //   // Redirect to homepage
    //   window.location.href = '/';
    // };

    const handleLogout = () => {
      // Clear all local storage
      localStorage.clear();
      // Clear all cookies (ensure they are removed properly)
      const allCookies = Object.keys(cookies.getAll());
      allCookies.forEach(cookie => cookies.remove(cookie, { path: '/' }));
      // Redirect to homepage
      window.location.href = '/';
  };
  

    const toggleDarkMode = () => {
        const newMode = !isDarkMode;
        setIsDarkMode(newMode);
        // Toggle the dark mode class on the body element
        document.body.classList.toggle('dark-mode', newMode);
        // Save the mode preference in local storage
        localStorage.setItem('isDarkMode', newMode);
    };

    // Function to check and apply dark mode from local storage on component mount
  const checkAndApplyDarkMode = () => {
    const savedMode = localStorage.getItem('isDarkMode');
    if (savedMode) {
      const newMode = savedMode === 'true'; // Convert the string back to boolean
      setIsDarkMode(newMode);
      document.body.classList.toggle('dark-mode', newMode);
    }
  };

  useEffect(() => {
    // Check for the user's designation from cookies
    const userDesignation = cookies.get('designation');
    setDesignation(userDesignation);

    // Check and apply dark mode on component mount
    checkAndApplyDarkMode();
  }, []);

  return (
    <>
        <nav class="navbar navbar-expand-md fixed-top">
            <div class="container">
            <a class="navbar-brand fw-bold" href="#"> 
            <img className='img-fluid rounded mx-1' src={logo} alt="Bales" style={{width: '3rem'}} /> 
              SV Trading LTD
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                {/* <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item"> <a class="nav-link" href="#">Import</a> </li>
                    <li class="nav-item"> <a class="nav-link" href="#">Export</a> </li>
                </ul> */}
                <button className='btn btn-danger btn-sm ms-auto' onClick={handleLogout}><i class="fa-solid fa-right-from-bracket"></i></button>
            </div>
            </div>
        </nav>
    </>
  )
}
