import React, { useState, useEffect } from 'react';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { fetchAllSalesW } from '../../api/api';

export default function Customerpendingorders() {
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [sales, setSales] = useState([]);

  useEffect(() => {
    fetchSales();
  }, []);

  const fetchSales = async () => {
    try {
      const salesData = await fetchAllSalesW();
      const approvedSales = salesData
        .filter(sale => sale.customer === localStorage.getItem('name'))
        .filter(sale => sale.saleqty > 0); // Exclude items with saleqty of 0

      // Sort sales by label to ensure items with the same label appear together
      const sortedSales = approvedSales.sort((a, b) => a.label.localeCompare(b.label));
      setSales(sortedSales);
    } catch (error) {
      console.error('Error fetching sales:', error);
    }
  };

  // Calculate totals for saleqty and saleprice
  const totalQty = sales.reduce((sum, sale) => sum + sale.saleqty, 0);
  const totalPrice = sales.reduce((sum, sale) => sum + sale.saleprice * sale.saleqty, 0);

  const getColorForLabel = (label) => {
    if (!label) return 'black'; // Default color if label is empty
    
    let hash = 0;
    for (let i = 0; i < label.length; i++) {
      hash = label.charCodeAt(i) + ((hash << 5) - hash); // Bitwise manipulation for better distribution
    }
  
    const colors = [
      'blue', 'red', 'purple', 'brown', 'magenta', 'teal', 'green',
      'maroon', 'navy', 'olive', 'crimson', 'black', 'periwinkle', 'azure'
    ];
    
    return colors[Math.abs(hash) % colors.length]; // Ensure positive index
  };
  

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <div className={`container-fluid my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {activeScreen === 'mastersheet' && 
          <div className='mt-5'>
            <Link to={`/homepage`}>
              <button className='btn btn-sm btn-dark float-end'>Back</button>
            </Link>
            <h5><u className='titilescolor'>Pending Orders</u></h5>

            <table className="table table-striped mt-2">
              <thead>
                <tr className='theads'>
                  <th>Label</th>
                  <th>Item</th>
                  <th>Sale QTY</th>
                  {/* <th>Sale Price</th> */}
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {sales.length < 1 ? (
                  <tr>
                    <td colSpan="5">
                      <h5>There are no orders</h5>
                    </td>
                  </tr>
                ) : (
                  sales.map((sale, index) => (
                    <tr key={sale.saleid}>
                      <td style={{padding: '0rem', backgroundColor: getColorForLabel(sale.label), color: '#fff'}}>{sale.label}</td>
                      <td style={{padding: '0rem', backgroundColor: getColorForLabel(sale.label), color: '#fff'}}>{sale.itemid.item}</td>
                      <td style={{padding: '0rem', backgroundColor: getColorForLabel(sale.label), color: '#fff'}}>{sale.saleqty}</td>
                      {/* <td style={{padding: '0rem', backgroundColor: getColorForLabel(sale.label), color: '#fff'}}>{(sale.saleprice * sale.saleqty).toFixed(2)}</td> */}
                      <td style={{padding: '0rem', backgroundColor: getColorForLabel(sale.label), color: '#fff'}}>{sale.createdAt}</td>
                    </tr>
                  ))
                )}
                {/* Total row */}
                {sales.length > 0 && (
                  <tr className='table-primary'>
                    <td><strong>Total</strong></td>
                    <td></td>
                    <td><strong>{totalQty}</strong></td>
                    {/* <td><strong>{totalPrice.toFixed(2)}</strong></td> */}
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
            <ToastContainer />
          </div>
          }
          
          {/* Other pages */}
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
