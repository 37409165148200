import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useNavigate, Link } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchAllstocks, triggerSyncStocks, deleteTodaysStock } from '../../api/api';

export default function Stocks() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [searchQuery, setSearchQuery] = useState('');
  const [containers, setContainers] = useState([]);
  const [items, setItems] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  let lastNonZeroBalance = 0; // Track the last non-zero balance


  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const fetchItems = async () => {
    try {
      const items = await fetchAllstocks();
      setItems(items);
      console.log('items am looking for', items)
      const uniqueContainers = [...new Set(items.map(item => item.label))];
      setContainers(uniqueContainers);
      console.log('mine', uniqueContainers)
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  useEffect(() => {
    // Set default date range to the current month
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const start = new Date(year, month, 1).toISOString().split('T')[0];
    const end = new Date(year, month + 1, 0).toISOString().split('T')[0];
    setStartDate(start);
    setEndDate(end);

    fetchItems();
  }, []);

  useEffect(() => {
    fetchItems();
  }, [startDate, endDate]);

  const getCurrentMonthDates = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const dates = [];
    for (let day = 1; day <= new Date(year, month + 1, 0).getDate(); day++) {
      dates.push(new Date(year, month, day).toLocaleDateString());
    }
    return dates;
  };

  const getDatesInRange = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const dates = [];
    while (startDate <= endDate) {
      dates.push(new Date(startDate).toLocaleDateString());
      startDate.setDate(startDate.getDate() + 1);
    }
    return dates;
  };

  const currentMonthDates = getCurrentMonthDates();
  const filteredDates = getDatesInRange(startDate, endDate);

  const groupedItemsByLabelAndDate = items.reduce((acc, item) => {
    const date = new Date(item.createdAt).toLocaleDateString();
  
    if (!acc[item.label]) {
      acc[item.label] = {};
    }
  
    if (!acc[item.label][item.itemid]) {
      acc[item.label][item.itemid] = {
        itemName: item.item, // Store the item.item for reference
        dates: {} // Store date-specific data here
      };
    }
  
    if (!acc[item.label][item.itemid].dates[date]) {
      acc[item.label][item.itemid].dates[date] = { in: 0, out: 0, balance: 0 };
    }
  
    // Update the in, out, and balance for the specific date
    acc[item.label][item.itemid].dates[date].in += item.instock;
    acc[item.label][item.itemid].dates[date].out += item.outstock;
    acc[item.label][item.itemid].dates[date].balance = item.stock;
  
    return acc;
  }, {});

  const filteredGroupedItemsByLabelAndDate = Object.keys(groupedItemsByLabelAndDate).reduce((acc, label) => {
    const filteredItemsByLabel = Object.keys(groupedItemsByLabelAndDate[label]).reduce((itemAcc, itemid) => {
      const itemData = groupedItemsByLabelAndDate[label][itemid];
      const filteredItemsByDate = filteredDates.reduce((dateAcc, date) => {
        const item = itemData.dates[date] || { in: 0, out: 0, balance: 0 };
        if (itemData.itemName.toLowerCase().includes(searchQuery.toLowerCase())) { // Use itemName for search
          dateAcc[date] = item;
        }
        return dateAcc;
      }, {});
  
      if (Object.keys(filteredItemsByDate).length > 0) {
        // Include itemid to distinguish between items with the same name
        itemAcc[`${itemData.itemName} (${itemid})`] = filteredItemsByDate;
      }
      return itemAcc;
    }, {});
  
    if (Object.keys(filteredItemsByLabel).length > 0) {
      acc[label] = filteredItemsByLabel;
    }
    return acc;
  }, {});   


  // ********************************************** 

  const generateXLSXData = () => {
    const labelOrder = [
        "J&K", "AUSTRALIA", "14S", "IMAM", "CC",
        "FAZLI", "MJS", "WASLIAH", "HUJA", "TR", "LE DUZIEM"
    ];

    const currentDate = new Date();
    const monthYear = currentDate.toLocaleString('default', { month: 'long', year: 'numeric' });
    
    const stockStatementRow = [`Stock Statement for the Month of: ${monthYear}`];
    const headers = ['Item', 'Opening Stock'];
    
    filteredDates.slice(1).forEach(date => {
        headers.push('', date, '');
    });
    headers.push('Total In', 'Total Out');

    const subHeaders = [''];
    subHeaders.push('');
    filteredDates.slice(1).forEach(() => {
        subHeaders.push('In', 'Out', 'Balance');
    });
    subHeaders.push('', '');

    const excelData = [];
    excelData.push(stockStatementRow);
    excelData.push(headers);
    excelData.push(subHeaders);

    const subtotals = { openingStock: 0, in: [], out: [], balance: [] };
    filteredDates.slice(1).forEach(() => {
        subtotals.in.push(0);
        subtotals.out.push(0);
        subtotals.balance.push(0);
    });

    let grandTotalIn = 0;
    let grandTotalOut = 0;
    
    const sortedLabels = Object.keys(filteredGroupedItemsByLabelAndDate).sort((a, b) => {
        const normalize = (str) => str.trim().toUpperCase();
        const normalizedLabelOrder = labelOrder.map(normalize);
        let indexA = normalizedLabelOrder.indexOf(normalize(a));
        let indexB = normalizedLabelOrder.indexOf(normalize(b));
        if (indexA === -1) indexA = normalizedLabelOrder.length;
        if (indexB === -1) indexB = normalizedLabelOrder.length;
        return indexA - indexB;
    });

    sortedLabels.forEach(label => {
        const itemsByLabel = filteredGroupedItemsByLabelAndDate[label];
        excelData.push([`${label.toUpperCase()}`, '']);

        Object.entries(itemsByLabel).forEach(([itemName, itemsByDate]) => {
            const row = [itemName.slice(0, -6)];
            let totalIn = 0;
            let totalOut = 0;
            let lastBalance = 0;

            const firstDate = filteredDates[0];
            const openingStock = itemsByDate[firstDate]?.balance || 0;
            row.push(openingStock);
            subtotals.openingStock += openingStock;
            
            filteredDates.slice(1).forEach((date, index) => {
                const item = itemsByDate[date] || { in: 0, out: 0, balance: 0 };
                if (index === 0) {
                    lastBalance = openingStock;
                } else {
                    item.balance = lastBalance + item.in - item.out;
                    lastBalance = item.balance;
                }

                row.push(item.in, item.out, item.balance);
                totalIn += item.in;
                totalOut += item.out;
                subtotals.in[index] += item.in;
                subtotals.out[index] += item.out;
                subtotals.balance[index] += item.balance;
            });

            row.push(totalIn, totalOut);
            excelData.push(row);
            grandTotalIn += totalIn;
            grandTotalOut += totalOut;
        });
        excelData.push([]);
    });

    const subtotalsRow = ['Subtotals', subtotals.openingStock];
    filteredDates.slice(1).forEach((_, index) => {
        subtotalsRow.push(subtotals.in[index], subtotals.out[index], subtotals.balance[index]);
    });
    subtotalsRow.push(grandTotalIn, grandTotalOut);
    excelData.push(subtotalsRow);

    return excelData;
};


// ****************************************************************************************** 

const handleExport = async () => {
  const data = generateXLSXData();
  
  // Create a new ExcelJS workbook
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Report");

  // Function to check if a value is a date
  const isDate = (value) => {
      if (value instanceof Date) return true;
      if (typeof value === "number") return false;
      return !isNaN(Date.parse(value?.toString()));
  };

  // Add and style the data
  data.forEach((row, rowIndex) => {
      const newRow = worksheet.addRow(row);

      if (row[0] && row.slice(1).every(cell => cell === '')) {
          newRow.getCell(1).font = { color: { argb: "FF0000" }, bold: true };
          newRow.getCell(1).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFF00" }
          };
          newRow.getCell(1).alignment = { horizontal: "center" };
      }

      // Apply border styling
      newRow.eachCell((cell, colNumber) => {
        const cellValue = cell.value?.toString().toLowerCase();

        if (isDate(cell.value)) { 
            // Remove left and right borders for date cells
            cell.border = {
                top: { style: "thin" },
                bottom: { style: "thin" },
                left: { style: "thin", color: { argb: "EEEEEE" } },
                right: { style: "thin", color: { argb: "EEEEEE" } }
            };
        } else {
            // Apply full borders for non-date cells
            cell.border = {
                top: { style: "thin" },
                bottom: { style: "thin" },
                left: { style: "thin" },
                right: { style: "thin" }
            };
        }

        // Apply thick left border to "in" column
        if (cellValue === "in") {
            cell.border.left = { style: "thick", color: { argb: "000000" } };
        }

        // Apply thick right border to "balance" column
        if (cellValue === "balance") {
            cell.border.right = { style: "thick", color: { argb: "000000" } };
        }
      });
  });

  const lastRow = worksheet.rowCount;
  const secondRow = 2;
  const thirdRow = 3;

  [secondRow, lastRow, thirdRow].forEach((rowNumber) => {
      if (rowNumber <= lastRow) {
          worksheet.getRow(rowNumber).eachCell((cell) => {
              cell.font = { color: { argb: "FF0000" }, bold: true };
          });
      }
  });

  // Save the file
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer], { type: "application/octet-stream" }), "mastersheet-report.xlsx");
};
    

// ********************************************************* 
 
  // function has been moved to Mastersheet, it works from there but not here 
  const handleCloseWS = async () => {
      const confirmed = window.confirm('Are you sure you want to Close stock for the day');
      if (!confirmed) {
        return;
      }
      
      try {
        await triggerSyncStocks();
        toast.success('Stock Closed for teh day successfully');
        fetchItems();
      } catch (error) {
        console.error('Error closing stock', error);
        toast.error('Error closing stock');
      }
  };

  const deleteTodayStock = async () => {
      try {
        await deleteTodaysStock();
        fetchItems();
        toast.success('Stock updated successfully.');
      } catch (error) {
        toast.error('Error updating stock. Please try again.');
      }
    };
  

  return (
    <>
      <Navbar />
      <div className='homepage'>
        {/* <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        /> */}
        <div className={`container-fluid my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {/* Mastersheet */}
          {activeScreen === 'mastersheet' && (
            <div className='mt-5'>
              <Link to='/mastersheet'>
                <button className='btn btn-danger btn-sm'>To Mastersheet</button>
              </Link>

              <button className="btn btn-dark btn-sm mx-1 float-end" onClick={deleteTodayStock}>
                Clear Todays Stock
              </button>

              <button
                  onClick={handleExport}
                  className="btn btn-success btn-sm mx-1 float-end"
                >
                  Export to XLSX
              </button>

              {/* <button className='btn mx-1 btn-info btn-sm' onClick={() => handleCloseWS()}>Close</button> */}

              <input
                className='form-control w-25 float-end'
                type="text"
                placeholder="Search items..."
                value={searchQuery}
                onChange={handleSearchChange}
              />

              <input
                type='date'
                id='endDate'
                className='form-control float-end w-25 mx-1'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />

              <input
                type='date'
                id='startDate'
                className='form-control float-end w-25 mx-1'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />

              <h5><u className='titilescolor'>Mastersheet</u></h5>
              
              {Object.keys(filteredGroupedItemsByLabelAndDate).length === 0 ? (
                  <h5 className='titilescolor'>Mastersheet has zero data</h5>
                ) : (
                  <div className="mt-3 text-success" style={{ maxHeight: '70vh', overflowY: 'auto', overflowX: 'auto' }}>
                    <table className="table table-striped" style={{ minWidth: '100%' }}>
                      {/* Table Header */}
                      <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
                        <tr className='theads'>
                          <th className='sticky-column' style={{ zIndex: 2 }}>Item</th>
                          {filteredDates.map((date, dateIndex) => (
                            <React.Fragment key={dateIndex}>
                              <th colSpan="3" style={{ border: '1px solid #dee2e6', textAlign: 'center', zIndex: 2 }}>{date}</th>
                            </React.Fragment>
                          ))}
                        </tr>
                        <tr>
                          <th className='sticky-column' style={{ zIndex: 2 }}></th>
                          {filteredDates.map((date, dateIndex) => (
                            <React.Fragment key={dateIndex}>
                              <th style={{ border: '1px solid #dee2e6' }}>In</th>
                              <th style={{ border: '1px solid #dee2e6' }}>Out</th>
                              <th style={{ border: '1px solid #dee2e6' }}>Balance</th>
                            </React.Fragment>
                          ))}
                        </tr>
                      </thead>

                      <tbody>
                        {/* Grouped Items */}
                        {/* {Object.keys(filteredGroupedItemsByLabelAndDate).map((label, labelIndex) => (
                          <React.Fragment key={labelIndex}>
                            <tr className="text-success">
                              <td className='sticky-column'  style={{ fontWeight: 'bold' }}>
                                <u className='text-success'>{label}</u>
                              </td>
                            </tr>
                            {Object.keys(filteredGroupedItemsByLabelAndDate[label]).map((itemName, itemIndex) => (
                              <tr key={itemIndex}>
                                <td className='sticky-column' style={{ minWidth: '200px', zIndex: 2 }}>{itemName.slice(0, -6)}</td>
                                {filteredDates.map((date, dateIndex) => (
                                  <React.Fragment key={dateIndex}>
                                    <td style={{ border: '1px solid #dee2e6', color: 'green' }}>{filteredGroupedItemsByLabelAndDate[label][itemName][date]?.in || 0}</td>
                                    <td style={{ border: '1px solid #dee2e6', color: 'orange' }}>{filteredGroupedItemsByLabelAndDate[label][itemName][date]?.out || 0}</td>
                                    <td style={{ border: '1px solid #dee2e6', color: 'red' }}>{filteredGroupedItemsByLabelAndDate[label][itemName][date]?.balance || 0}</td>
                                  </React.Fragment>
                                ))}
                              </tr>
                            ))}
                          </React.Fragment>
                        ))} */}

{Object.keys(filteredGroupedItemsByLabelAndDate).map((label, labelIndex) => {
  return (
    <React.Fragment key={labelIndex}>
      <tr className="text-success">
        <td className='sticky-column' style={{ fontWeight: 'bold' }}>
          <u className='text-success'>{label}</u>
        </td>
      </tr>
      {Object.keys(filteredGroupedItemsByLabelAndDate[label]).map((itemName, itemIndex) => {
        let lastNonZeroBalance = 0; // Initialize the last known non-zero balance
        return (
          <tr key={itemIndex}>
            <td className='sticky-column' style={{ minWidth: '200px', zIndex: 2 }}>{itemName.slice(0, -6)}</td>
            {filteredDates.map((date, dateIndex) => {
              // Retrieve in, out, and balance values
              const inValue = filteredGroupedItemsByLabelAndDate[label][itemName][date]?.in || 0;
              const outValue = filteredGroupedItemsByLabelAndDate[label][itemName][date]?.out || 0;
              let balance = filteredGroupedItemsByLabelAndDate[label][itemName][date]?.balance || 0;

              // Use last non-zero balance for future dates if balance is zero
              if (balance === 0 && new Date(date) > new Date()) {
                balance = lastNonZeroBalance;
              } else if (balance > 0) {
                lastNonZeroBalance = balance; // Update the last non-zero balance
              }

              return (
                <React.Fragment key={dateIndex}>
                  <td style={{ border: '1px solid #dee2e6', color: 'green' }}>{inValue}</td>
                  <td style={{ border: '1px solid #dee2e6', color: 'orange' }}>{outValue}</td>
                  <td style={{ border: '1px solid #dee2e6', color: 'red' }}>{balance}</td>
                </React.Fragment>
              );
            })}
          </tr>
        );
      })}
    </React.Fragment>
  );
})}


                        {/* Totals Row */}
                        <tr>
                          <td className='sticky-column' style={{ minWidth: '200px', zIndex: 2, fontWeight: 'bold' }}>Totals</td>
                            {filteredDates.map((date, dateIndex) => {
                              // Calculate totals for In, Out, and Balance independently across all items for each date
                              const totalIn = Object.keys(filteredGroupedItemsByLabelAndDate).reduce((sum, label) => {
                                return sum + Object.keys(filteredGroupedItemsByLabelAndDate[label]).reduce((itemSum, itemName) => {
                                  return itemSum + (filteredGroupedItemsByLabelAndDate[label][itemName][date]?.in || 0);
                                }, 0);
                              }, 0);

                              const totalOut = Object.keys(filteredGroupedItemsByLabelAndDate).reduce((sum, label) => {
                                return sum + Object.keys(filteredGroupedItemsByLabelAndDate[label]).reduce((itemSum, itemName) => {
                                  return itemSum + (filteredGroupedItemsByLabelAndDate[label][itemName][date]?.out || 0);
                                }, 0);
                              }, 0);

                              let totalBalance = Object.keys(filteredGroupedItemsByLabelAndDate).reduce((sum, label) => {
                                return sum + Object.keys(filteredGroupedItemsByLabelAndDate[label]).reduce((itemSum, itemName) => {
                                  return itemSum + (filteredGroupedItemsByLabelAndDate[label][itemName][date]?.balance || 0);
                                }, 0);
                              }, 0);

                              // Use last non-zero balance for future dates if balance is zero
                              if (totalBalance === 0 && new Date(date) > new Date()) {
                                totalBalance = lastNonZeroBalance;
                              } else if (totalBalance > 0) {
                                lastNonZeroBalance = totalBalance; // Update the last non-zero balance
                              }

                              return (
                                <React.Fragment key={dateIndex}>
                                  <td style={{ border: '1px solid #dee2e6', fontWeight: 'bold' }}>{Number(totalIn).toLocaleString()}</td>
                                  <td style={{ border: '1px solid #dee2e6', fontWeight: 'bold' }}>{Number(totalOut).toLocaleString()}</td>
                                  <td style={{ border: '1px solid #dee2e6', fontWeight: 'bold' }}>{Number(totalBalance).toLocaleString()}</td>
                                </React.Fragment>
                              );
                            })}

                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}

              <ToastContainer />
            </div>
          )}

          {/* Side panel components */}
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
