import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import SidePanel from '../../components/Sidepanel';
import Navbar from '../../components/Navbar';
import Dashboard from '../../pages/dashboard/Dashboard';
import Users from '../../pages/homepage/Users';
import Customers from '../../pages/homepage/Customers';
import Tasks from '../../pages/homepage/Tasks';
import Notes from '../../pages/homepage/Notes';
import Orders from '../../pages/homepage/Orders';
import { fetchAllSales } from '../../api/api';

export default function Salesreport() {
  const navigate = useNavigate();
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
  const [activeScreen, setActiveScreen] = useState('mastersheet');
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [customerfilter, setCustomerFilter] = useState('all');
  const [labelfilter, setLabelFilter] = useState('all');
  const [label, setLabels] = useState([]);
  const [itemFilter, setItemFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState(''); // State for search term
  const [customers, setCustomers] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [usdRate, setUsdRate] = useState(1);

  // Get today's date in the format 'YYYY-MM-DD'
  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  const [startDate, setStartDate] = useState(getTodayDate()); // Default to today's date
  const [endDate, setEndDate] = useState(getTodayDate()); 

  const toggleSidePanel = () => {
    setIsSidePanelOpen(!isSidePanelOpen);
  };

  const handleMenuItemClick = (screen) => {
    if (screen === 'dashboard') {
      navigate('/homepage');
    } else {
      setActiveScreen(screen);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    filterItems();
  }, [items, customerfilter, labelfilter, itemFilter, startDate, endDate, usdRate, searchTerm]);

  const fetchItems = async () => {
    try {
      const items = await fetchAllSales();
      // setItems(items);
      // setFilteredItems(items);

      // items.forEach(item => {
      //   console.log(`Item ID: ${item.id}, saleqty: ${item.saleqty}, cnqty: ${item.cnqty}`);
      // });

      console.log(items, '****************************************************')

      // Filter items where either saleqty or cnqty is greater than 0, and saleqty is not equal to cnqty
      // const filteredItems = items.filter(item => {
      //   // const validQty = (item.saleqty > 0 || item.cnqty > 0) && item.saleqty !== item.cnqty;
      //   const validQty = 
      //   ((item.saleqty > 0 || item.cnqty > 0 || item.rvqty > 0) && 
      //   item.saleqty !== (item.cnqty + item.rvqty));

      //   return validQty;
      // });

      const filteredItems = items.filter(item => 
        item.itemid && // Ensure itemid exists
        ((item.saleqty > 0 || item.cnqty > 0 || item.rvqty > 0) && 
        item.saleqty !== (item.cnqty + item.rvqty))
      );
      

      console.log(filteredItems)

      setFilteredItems(filteredItems);
      setItems(filteredItems);

      // const uniqueItems = [...new Set(items.map(item => item.itemid.item))];
      const uniqueItems = [...new Set(items
        .filter(item => item.itemid && item.itemid.item) // Ensure itemid exists
        .map(item => item.itemid.item))
      ];
      
      setAllItems(uniqueItems);

      const uniqueCustomers = [...new Set(items.map(item => item.customer))];
      setCustomers(uniqueCustomers);

      const uniqueLabels = [...new Set(items.map(item => item.label))];
      setLabels(uniqueLabels);

    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const filterItems = () => {
    let filtered = items;

    if (customerfilter !== 'all') {
      filtered = filtered.filter(item => item.customer === customerfilter);
    }

    if (labelfilter !== 'all') {
      filtered = filtered.filter(item => item.label === labelfilter);
    }

    if (itemFilter !== 'all') {
      filtered = filtered.filter(item => item.itemid.item === itemFilter);
    }

    if (startDate) {
      filtered = filtered.filter(item => {
        const itemDate = item.createdAt.split(' ')[0]; // Extract just the 'YYYY-MM-DD' part
        return new Date(itemDate) >= new Date(startDate);
      });
    }
  
    if (endDate) {
      filtered = filtered.filter(item => {
        const itemDate = item.createdAt.split(' ')[0]; // Extract just the 'YYYY-MM-DD' part
        return new Date(itemDate) <= new Date(endDate);
      });
    }

    if (searchTerm) {
      filtered = filtered.filter(item => 
        item.itemid.item.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredItems(filtered);
  };

  const handleCustomerFilterChange = (event) => {
    setCustomerFilter(event.target.value);
  };

  const handleLabelFilterChange = (event) => {
    setLabelFilter(event.target.value);
  };

  const handleItemFilterChange = (event) => {
    setItemFilter(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleUsdRateChange = (event) => {
    setUsdRate(event.target.value);
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const calculateSubtotal = () => {
    return filteredItems.reduce((total, item) => total + item.saletotal, 0);
  };

  const calculateKSHPTotal = () => {
    return filteredItems.reduce((total, item) => total + (item.saleprice - item.commision), 0);
  };

  const calculateQTYsub = () => {
    return filteredItems.reduce((total, item) => total + (item.saleqty - (item.cnqty + item.rvqty)), 0);
  };

  const calculateKSHSUBTotal = () => {
    return filteredItems.reduce((total, item) => total + (item.saletotal - (item.commision * item.saleqty) ), 0);
  };

  const calculateUsdSubtotal = () => {
    return filteredItems.reduce((total, item) => total + ((item.saletotal - (item.commision * item.saleqty)) / usdRate), 0);
  };

  const calculateUsdSubtotalprice = () => {
    return filteredItems.reduce((total, item) => total + ((item.saleprice - item.commision) / usdRate), 0);
  };

  const csvHeaders = [
    { label: "Item", key: "itemid.item" },
    { label: "QTY", key: "saleqty" },
    { label: "KSH.P", key: "saleprice" },
    { label: "KSH.T", key: "saletotal" },
    { label: "USD.P", key: "usdSalePrice" },
    { label: "USD.T", key: "usdSaleTotal" },
    { label: "Label", key: "label" },
    { label: "Customer", key: "customer" },
    { label: "Seller", key: "seller" },
    { label: "Created", key: "createdAt" }
  ];  

  // Group items by label
  const groupItemsByLabel = () => {
    const grouped = {};
    filteredItems.forEach(item => {
      // Grouping by label
      if (!grouped[item.label]) {
        grouped[item.label] = [];
      }
      grouped[item.label].push({
        ...item,
        usdSalePrice: (item.saleprice / usdRate).toFixed(2),
        usdSaleTotal: (item.saletotal / usdRate).toFixed(2),
      });
    });
    return grouped;
  };


  // const prepareCSVData = () => {
  //   const grouped = groupItemsByLabel();
  //   const csvData = [];
  
  //   Object.keys(grouped).forEach(label => {
  //     // Sort items alphabetically by "itemid.item"
  //     const sortedItems = grouped[label].sort((a, b) =>
  //       a.itemid.item.localeCompare(b.itemid.item)
  //     );
  
  //     // Merge items with the same item name, label, and price
  //     const mergedItems = {};
  //     sortedItems.forEach(item => {
  //       const key = `${item.itemid.item}_${item.label}_${item.saleprice - item.commision}`;
  //       if (!mergedItems[key]) {
  //         mergedItems[key] = {
  //           ...item,
  //           totalQty: item.saleqty,
  //           totalSale: item.saletotal,
  //           totalUsdSale: item.usdSaleTotal,
  //         };
  //       } else {
  //         // Merge quantities and totals
  //         mergedItems[key].totalQty += item.saleqty;
  //         mergedItems[key].totalSale += item.saletotal;
  //         mergedItems[key].totalUsdSale = (
  //           parseFloat(mergedItems[key].totalUsdSale) +
  //           parseFloat(item.usdSaleTotal)
  //         ).toFixed(2);
  //       }
  //     });
  
  //     csvData.push(
  //       ...Object.values(mergedItems).map(item => ({
  //         "itemid.item": item.itemid.item,
  //         saleqty: item.totalQty - (item.cnqty + item.rvqty),
  //         saleprice: item.saleprice - item.commision,
  //         saletotal: (item.totalQty - (item.cnqty + item.rvqty)) * (item.saleprice - item.commision),
  //         usdSalePrice: ((item.saleprice - item.commision) / usdRate).toFixed(2),
  //         usdSaleTotal: ((item.saletotal - (item.commision * item.saleqty)) / usdRate).toFixed(2),
  //         // (item.saletotal - (item.commision * item.saleqty))
  //         // usdSaleTotal: item.totalUsdSale,
  //         label: item.label,
  //         customer: item.customer,
  //         seller: item.seller,
  //         createdAt: item.createdAt,
  //       }))
  //     );
  //   });
  
  //   return csvData;
  // };  

  const prepareCSVData = () => {
    const grouped = groupItemsByLabel();
    const csvData = [];

    const labelOrder = [
      "J&K", "AUSTRALIA", "14S", "IMAM", "CC",
      "FAZLI", "MJS", "WASLIAH", "HUJJAH", "TR", "LE DUZIEM"
    ];

    // for testing local 
    // const labelOrder = [
    //   "J&K", "IMAM", "14START", "AUST"
    // ];

    // Sort the labels according to the labelOrder
    // const sortedLabels = Object.keys(grouped).sort((a, b) => {
    //     return labelOrder.indexOf(a) - labelOrder.indexOf(b);
    // });

    const sortedLabels = Object.keys(grouped).sort((a, b) => {
      const normalize = (str) => str.trim().toUpperCase(); // Remove spaces & normalize case
      const normalizedLabelOrder = labelOrder.map(normalize);
  
      let indexA = normalizedLabelOrder.indexOf(normalize(a));
      let indexB = normalizedLabelOrder.indexOf(normalize(b));
  
      if (indexA === -1) indexA = normalizedLabelOrder.length; // Push unknown labels to the end
      if (indexB === -1) indexB = normalizedLabelOrder.length;
  
      return indexA - indexB;
    });

    // Process each label in the sorted order
    sortedLabels.forEach(label => {
        // Sort items alphabetically by "itemid.item"
        const sortedItems = grouped[label].sort((a, b) =>
            a.itemid.item.localeCompare(b.itemid.item)
        );

        // Merge items with the same item name, label, and price
        const mergedItems = {};
        sortedItems.forEach(item => {
            const key = `${item.itemid.item}_${item.label}_${item.saleprice - item.commision}`;
            if (!mergedItems[key]) {
                mergedItems[key] = {
                    ...item,
                    totalQty: item.saleqty,
                    totalSale: item.saletotal,
                    totalUsdSale: item.usdSaleTotal,
                };
            } else {
                // Merge quantities and totals
                mergedItems[key].totalQty += item.saleqty;
                mergedItems[key].totalSale += item.saletotal;
                mergedItems[key].totalUsdSale = (
                    parseFloat(mergedItems[key].totalUsdSale) +
                    parseFloat(item.usdSaleTotal)
                ).toFixed(2);
            }
        });

        // Add the merged items to the CSV data
        csvData.push(
            ...Object.values(mergedItems).map(item => ({
                "itemid.item": item.itemid.item,
                saleqty: item.totalQty - (item.cnqty + item.rvqty),
                saleprice: item.saleprice - item.commision,
                saletotal: (item.totalQty - (item.cnqty + item.rvqty)) * (item.saleprice - item.commision),
                usdSalePrice: ((item.saleprice - item.commision) / usdRate).toFixed(2),
                usdSaleTotal: ((item.saletotal - (item.commision * item.saleqty)) / usdRate).toFixed(2),
                label: item.label,
                customer: item.customer,
                seller: item.seller,
                createdAt: item.createdAt,
            }))
        );
    });

    return csvData;
};

//   const prepareCSVData = () => {
//     const grouped = groupItemsByLabel();
//     const csvData = [];

//     // Define the desired label order
//     const labelOrder = [
//         "J&K", "AUSTRALIA", "14S", "IMAM", "CC",
//         "FAZLI", "MJS", "WASLIAH", "HUJA", "TR", "LE DUZIEM"
//     ];

//     // Sort labels based on predefined order
//     const sortedLabels = Object.keys(grouped).sort((a, b) => {
//         return labelOrder.indexOf(a) - labelOrder.indexOf(b);
//     });

//     sortedLabels.forEach(label => {
//         const sortedItems = grouped[label].sort((a, b) =>
//             a.itemid.item.localeCompare(b.itemid.item)
//         );

//         const mergedItems = {};
//         sortedItems.forEach(item => {
//             const key = `${item.itemid.item}_${item.label}_${item.saleprice - item.commision}`;
//             if (!mergedItems[key]) {
//                 mergedItems[key] = {
//                     ...item,
//                     totalQty: item.saleqty,
//                     totalSale: item.saletotal,
//                     totalUsdSale: item.usdSaleTotal,
//                 };
//             } else {
//                 mergedItems[key].totalQty += item.saleqty;
//                 mergedItems[key].totalSale += item.saletotal;
//                 mergedItems[key].totalUsdSale = (
//                     parseFloat(mergedItems[key].totalUsdSale) +
//                     parseFloat(item.usdSaleTotal)
//                 ).toFixed(2);
//             }
//         });

//         csvData.push(
//             ...Object.values(mergedItems).map(item => ({
//                 "itemid.item": item.itemid.item,
//                 saleqty: item.totalQty - (item.cnqty + item.rvqty),
//                 saleprice: item.saleprice - item.commision,
//                 saletotal: (item.totalQty - (item.cnqty + item.rvqty)) * (item.saleprice - item.commision),
//                 usdSalePrice: ((item.saleprice - item.commision) / usdRate).toFixed(2),
//                 usdSaleTotal: ((item.saletotal - (item.commision * item.saleqty)) / usdRate).toFixed(2),
//                 label: item.label,
//                 customer: item.customer,
//                 seller: item.seller,
//                 createdAt: item.createdAt,
//             }))
//         );
//     });

//     return csvData;
// };


  // const prepareCSVData = () => {
  //   const grouped = groupItemsByLabel();
  //   const csvData = [];
  //   Object.keys(grouped).forEach(label => {
  //     // csvData.push({ label: `--- ${label} ---` }); // Label header
  
  //     // Merge items with the same item name, label, and price
  //     const mergedItems = {};
  //     grouped[label].forEach(item => {
  //       const key = `${item.itemid.item}_${item.label}_${item.saleprice}`;
  //       if (!mergedItems[key]) {
  //         mergedItems[key] = {
  //           ...item,
  //           totalQty: item.saleqty,
  //           totalSale: item.saletotal,
  //           totalUsdSale: item.usdSaleTotal,
  //         };
  //       } else {
  //         // Merge quantities and totals
  //         mergedItems[key].totalQty += item.saleqty;
  //         mergedItems[key].totalSale += item.saletotal;
  //         mergedItems[key].totalUsdSale = (
  //           parseFloat(mergedItems[key].totalUsdSale) +
  //           parseFloat(item.usdSaleTotal)
  //         ).toFixed(2);
  //       }
  //     });
  
  //     csvData.push(
  //       ...Object.values(mergedItems).map(item => ({
  //         "itemid.item": item.itemid.item,
  //         saleqty: item.totalQty - item.cnqty,
  //         saleprice: item.saleprice - item.commision,
  //         saletotal: (item.totalQty - item.cnqty) * (item.saleprice - item.commision),
  //         usdSalePrice: ((item.saleprice - item.commision) / usdRate).toFixed(2),
  //         usdSaleTotal: item.totalUsdSale,
  //         label: item.label,
  //         customer: item.customer,
  //         seller: item.seller,
  //         createdAt: item.createdAt,
  //       }))
  //     );
  //   });
  
  //   return csvData;
  // }; 

  return (
    <>
      <Navbar />
      <div className='homepage'>
        <SidePanel
          isOpen={isSidePanelOpen}
          toggleSidePanel={toggleSidePanel}
          handleMenuItemClick={handleMenuItemClick}
        />
        <div className={`container-fluid my-5 ${isSidePanelOpen ? 'expanded' : 'closed'}`}>
          {activeScreen === 'mastersheet' && (
            <div className='mt-4'>
              <h5><u className='titilescolor'>Sales Report</u></h5>
              <div className='d-flex justify-content-between'>
                <div className='mt-3'>
                  <input
                    type='number'
                    className='form-control d-inline-block w-auto me-2'
                    placeholder='USD Rate'
                    value={usdRate}
                    onChange={handleUsdRateChange}
                  />
                  <input
                    type='date'
                    className='form-control d-inline-block w-auto me-2'
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                  <input
                    type='date'
                    className='form-control d-inline-block w-auto me-2'
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                  <select className='form-select d-inline-block w-auto' value={customerfilter} onChange={handleCustomerFilterChange}>
                    <option value='all'>All Customers</option>
                    {customers.map((customer, index) => (
                      <option key={index} value={customer}>{customer}</option>
                    ))}
                  </select>

                  <select className='form-select d-inline-block w-auto mx-2' value={labelfilter} onChange={handleLabelFilterChange}>
                    <option value='all'>Labels</option>
                    {label.map((item, index) => (
                      <option key={index} value={item}>{item}</option>
                    ))}
                  </select>

                  <select className='form-select d-inline-block w-auto' value={itemFilter} onChange={handleItemFilterChange}>
                    <option value='all'>All Items</option>
                    {allItems.map((item, index) => (
                      <option key={index} value={item}>{item}</option>
                    ))}
                  </select>
                  <input
                    type='text'
                    className='form-control d-inline-block w-auto me-2'
                    placeholder='Search by item'
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                  />
                  <CSVLink 
                    data={prepareCSVData()}
                    headers={csvHeaders}
                    filename={"sales-report.csv"}
                    className="btn btn-success btn-sm ms-2 mt-2"
                  >
                    Export to CSV
                  </CSVLink>
                </div>
              </div>
              <div className='row text-center mt-4'>
                <table className="table table-striped">
                  <thead>
                    <tr className='theads'>
                      <td><strong>#</strong></td>
                      <th>Item</th>
                      <th>QTY</th>
                      <th>KSH.P</th>
                      {/* <th>KSH.T</th>
                      <th>USD.P</th>
                      <th>USD.T</th> */}
                      <th>Label</th>
                      <th>Customer</th>
                      <th>Seller</th>
                      <th>Created</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredItems.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item.itemid.item}</td>
                        <td>{item.saleqty - (item.cnqty + item.rvqty)}</td>
                        <td className='text-success'>{Number(item.saleprice - item.commision).toLocaleString()}</td>
                        {/* <td className='text-success'>{Number((item.saleqty - (item.cnqty + item.rvqty)) * (item.saleprice - item.commision)).toLocaleString()}</td>
                        <td className='text-danger'>{((item.saleprice - item.commision) / usdRate).toFixed(2)}</td>
                        <td className='text-danger'>{(((item.saleqty - (item.cnqty + item.rvqty)) * (item.saleprice - item.commision) / usdRate)).toFixed(2)}</td> */}
                        <td>{item.label}</td>
                        <td>{item.customer}</td>
                        <td>{item.seller}</td>
                        <td>{item.createdAt.substring(0, 10)}</td>
                      </tr>
                    ))}
                    <tr>
                      <td className='text-success'><strong>Subtotal</strong></td>
                      <td colSpan="1"></td>
                      <td className='text-success'><strong>{calculateQTYsub()}</strong></td>
                      <td className='text-success'><strong>{Number(calculateKSHPTotal()).toLocaleString()}</strong></td>
                      {/* <td className='text-success'><strong>{Number(calculateKSHSUBTotal()).toLocaleString()}</strong></td>
                      <td className='text-danger'><strong>{calculateUsdSubtotalprice().toFixed(2)}</strong></td>
                      <td className='text-danger'><strong>{calculateUsdSubtotal().toFixed(2)}</strong></td> */}
                      <td colSpan="5"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {activeScreen === 'dashboard' && <Dashboard />}
          {activeScreen === 'users' && <Users />}
          {activeScreen === 'customers' && <Customers />}
          {activeScreen === 'tasks' && <Tasks />}
          {activeScreen === 'notes' && <Notes />}
          {activeScreen === 'orders' && <Orders />}
        </div>
      </div>
    </>
  );
}
